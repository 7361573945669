
import { gql } from '@apollo/client'

export const GET_REQUEST_QUESTIONS = gql`
query allRequestQuestions($adminId: ID, $query: PaginationQuery, $userFind: UserFind) {
	allRequestQuestions(adminId: $adminId, query: $query, userFind: $userFind) {
			total
			limit
			page
			pages
			requestQuestions {
					id
					status
					isAnswerViewed
					created_at
					updated_at
					lastAnswerDate
					requestCategory {
							_id
							name
					}
					requestSubcategory {
							_id
							name
					}
					queries {
							media
							desc
							title
							created_at
							adminId {
								id
								nickname
							}
					}
					assigned {
							id
							nickname
							lastName
							firstName
					}
					user {
							id
							username
							nickname
							firstName
							phoneNumber

					}
			}
	}
}
`

export const UPDATE_USER_REQUEST = gql`
  mutation updatedUserRequestQuestion($id: ID!, $input: RequestQuestionInput) {
    updatedUserRequestQuestion(id: $id, input: $input) {
      id
    }
  }
`

export const GET_ADMINS = gql`
	query usersByRole {
		usersByRole(role: "admin") {
				id
				nickname
		}
	}
`
