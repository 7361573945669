import React from 'react'
import { Table } from 'antd'
import dayjs from 'dayjs'

const SupportBody = ({
  requests,
  total,
  setPage,
  page,
  setSelectedRequest,
  setShowRequestModal,
  renderStatus,
}) => {
  const columns = [
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Имя
          </div>
        )
      },
      dataIndex: ['user', 'nickname'],
      width: '20%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>{text}</div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Категория
          </div>
        )
      },
      dataIndex: ['requestCategory', 'name'],
      width: '25%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>{text}</div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Ответственный
          </div>
        )
      },
      dataIndex: ['assigned', 'nickname'],
      width: '20%',
      render: (text, item) => (
        <div
          style={{
            fontFamily: 'GraphikLCGMedium',
            color: text ? 'black' : 'gray',
          }}
        >
          {item?.assigned?.nickname || 'Отсутствует'}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Статус
          </div>
        )
      },
      dataIndex: 'status',
      width: '20%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {renderStatus(text)}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div
            style={{
              fontSize: 14,
              fontFamily: 'GraphikLCGMedium',
              color: 'gray',
            }}
          >
            Дата получения
          </div>
        )
      },
      dataIndex: 'created_at',
      width: '20%',
      render: (text, item) => (
        <div style={{ fontFamily: 'GraphikLCGMedium' }}>
          {dayjs(text).format('DD.MM.YY - HH:mm')}
        </div>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={requests}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          defaultPageSize: 10,
          total: total,
          current: page,
          onChange: (page, pageSize) => {
            setPage(page)
          },
          showSizeChanger: false,
        }}
        onRow={(item) => {
          return {
            onClick: (itm) => {
              setSelectedRequest(item)
              setShowRequestModal(true)
            },
          }
        }}
      />
    </>
  )
}

export default SupportBody
