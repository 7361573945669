import React from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import withMainLayout from '../../hocs/withMainLayout'
import Support from './Support'

import {
  GET_REQUEST_QUESTIONS,
  GET_ADMINS,
  UPDATE_USER_REQUEST,
} from './Support.queries'
import dayjs from 'dayjs'
import { useAuth } from '../../context/useAuth'

const SupportContainer = () => {
  const { user } = useAuth()
  const [choosed, setChoosed] = React.useState(1)
  const [requests, setRequests] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [total, setTotal] = React.useState(1)
  const [search, setSearch] = React.useState('')
  const [filter, setFilter] = React.useState('')
  const [selectedRequest, setSelectedRequest] = React.useState()
  const [showRequestModal, setShowRequestModal] = React.useState(false)
  const [answer, setAnswer] = React.useState('')
  const [admins, setAdmins] = React.useState([])
  const [choosedAdmin, setChoosedAdmin] = React.useState('')
  const [updateType, setUpdateType] = React.useState('')

  const [
    getRequestQuestions,
    { data, loading: loadingRequests, error, refetch },
  ] = useLazyQuery(GET_REQUEST_QUESTIONS)

  const {
    data: adminsData,
    loading: adminsLoading,
    error: adminsError,
  } = useQuery(GET_ADMINS)

  const addAdminForRequest = () => {
    setSelectedRequest({
      ...selectedRequest,
      status: updateType === 'addAdmin' ? 'inProgress' : selectedRequest.status,
      assigned: {
        id: updateType === 'addAdmin' ? user?.id : choosedAdmin?.id,
        nickname:
          updateType === 'addAdmin' ? user?.nickname : choosedAdmin?.nickname,
      },
    })
  }

  const createAdminAnswer = () => {
    setSelectedRequest({
      ...selectedRequest,
      status: updateType === 'adminAnswer' ? 'moreDataForAdmin' : 'finished',
      isAnswerViewed: false,
      lastAnswerDate: dayjs().toISOString(),
      queries: [
        ...selectedRequest.queries,
        {
          desc: answer,
          created_at: dayjs(),
          adminId: { id: user?.id, nickname: user?.nickname },
        },
      ],
    })
    setAnswer('')
  }

  const [updateRequestQuestion, { loading: loadingUpdateRequest }] =
    useMutation(UPDATE_USER_REQUEST, {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      onCompleted() {
        refetch()
        if (updateType === 'addAdmin' || updateType === 'changeAdmin') {
          addAdminForRequest()
        } else if (
          updateType === 'adminAnswer' ||
          updateType === 'adminReport'
        ) {
          createAdminAnswer()
        }
      },
      onError(err) {
        toast.error(`Error ${err.message}`)
      },
    })

  React.useEffect(() => {
    if (selectedRequest && !selectedRequest?.assigned && user) {
      setUpdateType('addAdmin')
      updateRequestQuestion({
        variables: {
          id: selectedRequest?.id,
          input: {
            assigned: user?.id,
            status: 'inProgress',
          },
        },
      })
    }
  }, [updateRequestQuestion, selectedRequest, user])

  React.useEffect(() => {
    getRequestQuestions({
      variables: {
        adminId: choosed === 2 ? user?.id : '',
        query: {
          page: page,
          limit: 10,
        },
        userFind: {
          text: search,
          filter: filter,
        },
      },
    })
  }, [getRequestQuestions, page, search, filter, choosed, user])

  React.useEffect(() => {
    if (data && !loadingRequests && !error) {
      setRequests(data?.allRequestQuestions?.requestQuestions)
      setTotal(data?.allRequestQuestions?.total)
    }
  }, [data, loadingRequests, error])

  React.useEffect(() => {
    if (adminsData && !adminsLoading && !adminsError) {
      setAdmins(adminsData.usersByRole)
    }
  }, [adminsData, adminsLoading, adminsError])

  const removeTypename = (objects) => {
    return objects.map((obj) => {
      const { __typename, ...rest } = obj
      if (rest?.adminId) {
        const { __typename: type, ...restAdminId } = rest.adminId
        return { ...rest, adminId: restAdminId?.id }
      }
      return rest
    })
  }

  const onSubmitAdminAnswer = (type) => {
    if (type === 'moreData') {
      setUpdateType('adminAnswer')
    } else {
      setUpdateType('adminReport')
    }
    const newQuery = {
      title:
        type === 'moreData'
          ? 'Пожалуйста, дополните ваш запрос'
          : 'Ваш запрос закрыт',
      desc: answer,
      adminId: user?.id,
      created_at: dayjs().toISOString(),
    }
    const updatedObjArr = removeTypename(selectedRequest.queries)
    const updatedQueries = [...updatedObjArr, newQuery]
    updateRequestQuestion({
      variables: {
        id: selectedRequest?.id,
        input: {
          user: selectedRequest?.user?.id,
          status: type === 'moreData' ? 'moreDataForAdmin' : 'finished',
          isAnswerViewed: false,
          lastAnswerDate: dayjs().toISOString(),
          queries: updatedQueries,
        },
      },
    })
  }

  const changeAdmin = () => {
    if (selectedRequest && choosedAdmin?.id && user) {
      setUpdateType('changeAdmin')
      updateRequestQuestion({
        variables: {
          id: selectedRequest?.id,
          input: {
            assigned: choosedAdmin?.id,
          },
        },
      })
    }
  }

  return (
    <Support
      choosed={choosed}
      setChoosed={setChoosed}
      requests={requests}
      total={total}
      setPage={setPage}
      page={page}
      setSearch={setSearch}
      setFilter={setFilter}
      setSelectedRequest={setSelectedRequest}
      selectedRequest={selectedRequest}
      setShowRequestModal={setShowRequestModal}
      showRequestModal={showRequestModal}
      setAnswer={setAnswer}
      onSubmitAdminAnswer={onSubmitAdminAnswer}
      admins={admins}
      setChoosedAdmin={setChoosedAdmin}
      choosedAdmin={choosedAdmin}
      changeAdmin={changeAdmin}
      loading={loadingUpdateRequest}
      loadingRequests={loadingRequests}
    />
  )
}

export default withMainLayout(SupportContainer)
