import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Typography, Select } from 'antd'
import styled from 'styled-components'

const { TextArea } = Input
const { Text } = Typography

const InputItemContainer = styled.div`
  margin-bottom: 20px;
`

const CreateNotificationModal = ({
  isVisible,
  setIsVisible,
  heading,
  message,
  setHeading,
  setType,
  type,
  setMessage,
  handleSendNotification,
}) => {
  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      title="Новое уведомление"
      onOk={handleSendNotification}
    >
      <InputItemContainer>
        <Text>Заголовок</Text>
        <Input value={heading} onChange={(e) => setHeading(e.target.value)} />
      </InputItemContainer>
      <InputItemContainer>
        <Text>Описание</Text>
        <TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </InputItemContainer>
      <Select
        placeholder="Тип сообщения"
        showSearch
        value={type}
        onChange={(value) => setType(value)}
      >
        <Select.Option key="broadcast" value="broadcast">
          broadcast
        </Select.Option>
        <Select.Option key="onlyUsers" value="onlyUsers">
          onlyUsers
        </Select.Option>
        <Select.Option key="onlyCertainLevel" value="onlyCertainLevel">
          onlyCertainLevel
        </Select.Option>
      </Select>
    </Modal>
  )
}

CreateNotificationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setHeading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired,
}

export default CreateNotificationModal
