import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import styled from 'styled-components'
import CreateNotificationModal from './CreateNotification.modal'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Notifications = ({
  columns,
  notifications,
  isVisible,
  setIsVisible,
  heading,
  message,
  setMessage,
  setHeading,
  type,
  setType,
  handleSendNotification,
  pagination,
  onPageChange
}) => {
  return (
    <>
      <Table
        dataSource={notifications}
        columns={columns}
        rowKey={(item) => item.id}
        pagination={{
          current: pagination.page,
          total: pagination.total,
          pageSize: pagination.limit,
          onChange: onPageChange,
        }}
        title={() => (
          <StyledHeaderContainer>
            <Button type="primary" onClick={() => setIsVisible(true)}>
              <PlusOutlined /> Новая нотификация
            </Button>
          </StyledHeaderContainer>
        )}
      />
      {isVisible && (
        <CreateNotificationModal
          {...{
            isVisible,
            setIsVisible,
            heading,
            message,
            setHeading,
            setMessage,
            type,
            setType,
            handleSendNotification,
          }}
        />
      )}
    </>
  );
};

Notifications.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setHeading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  handleSendNotification: PropTypes.func.isRequired
}

export default Notifications
