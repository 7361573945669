import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import SearchLogo from '../../images/search.svg'
import { MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import { Select } from '@mui/material'
import { styled as styledMui } from '@mui/system'

const Container = styled.div`
  padding: 8px;
  align-items: center;
`
const Text = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: black;
  cursor: pointer;
  border: 0px;
  font-family: 'GraphikLCGMedium';
  background: white;
`
const Slider = styled.div`
  border-top: 2px solid black;
  margin-left: ${(props) =>
    props.all
      ? '6px'
      : props.my
      ? '102px'
      : '0'};
  width: 75px;
  border-radius: 10px;
  transition: all 0.3s;
`

const Search = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`

const StyledFormControl = styledMui(FormControl)(({ theme }) => ({
  minWidth: 165,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  backgroundColor: 'transparent',
  padding: 3,
  justifyContent: 'center',
}))

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  height: 40px;
  width: 240px;
  background: rgba(0, 0, 0, 0.04);
  background-image: url(${SearchLogo});
  background-repeat: no-repeat;
  background-position: 5%;
  padding: 0 44px;
  border: none;
  color: rgba(0, 0, 0, 0.2);
  &:focus {
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.08);
    background-image: url(${SearchLogo});
    background-repeat: no-repeat;
    background-position: left center;
    background-position: 5%;
    color: #000;
  }
`
const SupportHeader = ({choosed, setChoosed, setSearch, setFilter}) => {
  const [value, setValue] = React.useState('')
  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const statusData = [
    {value: 'inProgress', title: 'В обработке'}, 
    {value: 'notViewed', title: 'Не прочитано'},
    {value: 'moreDataForAdmin', title: 'Дополняется'},
    {value: 'finished', title: 'Решено'},
  ]

	return (
		<Container>
      <Text
        onClick={() => setChoosed(1)}
        style={{
          marginRight: 8,
          color: choosed === 1 ? 'black' : 'rgba(0, 0, 0, 0.50)',
        }}
      >
        Все заявки
      </Text>
      <Text
        onClick={() => setChoosed(2)}
        style={{
          marginRight: 8,
          color: choosed === 2 ? 'black' : 'rgba(0, 0, 0, 0.50)',
          }}
      >
        Мои заявки
      </Text>
				<Slider
          all={choosed === 1}
          my={choosed === 2}
        />
      <RightContainer>
      <StyledFormControl variant="standard">
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            disableUnderline
            style={{
              paddingLeft: 10,
              fontFamily: 'GraphikLCGMedium',
              fontSize: 14,
            }}
          >
            <MenuItem
              style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
              value=""
              onClick={() => setFilter('')}
            >
              Все заявки
            </MenuItem>
            {statusData?.map((item, index) => (
              <MenuItem
                key={index}
                style={{ fontFamily: 'GraphikLCGMedium', fontSize: 14 }}
                value={item.value}
                onClick={() => {
                  setFilter(item.value)
                }}
              >
                {item?.title}
              </MenuItem>
            ))}
            </Select>
          </StyledFormControl>
        <Search>
          <StyledInput
            placeholder="Поиск"
            onChange={(e) => {
              const inputValue = e.target.value.toLowerCase()
              setSearch(inputValue)
            }}
          />
        </Search>
      </RightContainer>
		</Container>
	)

}

export default SupportHeader